import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Base from '../components/Base'
import Meta from '../components/Meta'
import { ListLink } from './styles'

const Categories = props => {
  const { category, pathName } = props.pageContext
  const { edges, totalCount } = props.data.allMdx
  const postWord = `Post${totalCount > 1 ? 's' : ''}`

  return (
    <Base pathName={pathName}>
      <Meta
        location={props.location}
        pageTitle={`Posts in ${category} category`}
      />
      <h2>
        {postWord} filed under the category {category}
      </h2>
      <ul>
        {edges.map(({ node }) => (
          <li key={node.id}>
            <ListLink to={node.fields.slug}>{node.frontmatter.title}</ListLink>
          </li>
        ))}
      </ul>
      <Link to="/categories">All categories</Link>
    </Base>
  )
}

Categories.propTypes = {
  location: PropTypes.string,
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
    pathName: PropTypes.string
  }),
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string.isRequired,
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired
            }),
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired
            })
          })
        }).isRequired
      )
    })
  })
}

export default Categories

export const pageQuery = graphql`
  query ($category: String) {
    allMdx(
      sort: { order: DESC, fields: frontmatter___date }
      limit: 1000
      filter: { frontmatter: { categories: { in: [$category] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
          id
        }
      }
    }
  }
`
